@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-family: "Inter", sans-serif
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-scrollbar::-webkit-scrollbar {
    height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@layer base {
    :root {
        --background: 250 45% 96%;
        --foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --accent: 244 75% 65%;
        --accent-foreground: 0 0% 100%;

        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;

        --ring: 215 20.2% 65.1%;

        --radius: 0.5rem;

        --chart-1: 12 76% 61%;
        --chart-2: 210 70% 45%;
        --chart-3: 43 74% 66%;
        --chart-4: 173 58% 39%;
        --chart-5: 220 30% 40%;
        --chart-other: 0 0% 50%;
    }

    @media (prefers-color-scheme: dark) {
        :root {
            --background: 220 13% 12%;
            --foreground: 210 40% 98%;

            --card: 220 13% 17%;
            --card-foreground: 210 40% 98%;

            --popover: 220 13% 7%;
            --popover-foreground: 210 40% 98%;

            --primary: 217 91% 60%;
            --primary-foreground: 210 40% 98%;

            --secondary: 215 25% 27%;
            --secondary-foreground: 210 40% 98%;

            --muted: 215 25% 20%;
            --muted-foreground: 215 20% 65%;

            --accent: 244 75% 65%;
            --accent-foreground: 244 75% 98%;

            --border: 215 25% 27%;
            --input: 215 25% 27%;
            --ring: 217 91% 60%;

            --destructive: 0 84% 60%;
            --destructive-foreground: 210 40% 98%;

            --chart-1: 217 91% 60%;
            --chart-2: 269 66% 67%;
            --chart-3: 31 85% 57%;
            --chart-4: 161 84% 45%;
            --chart-5: 338 71% 62%;
            --chart-other: 215 25% 27%;

            --radius: 0.5rem;
        }
    }
}